import * as React from 'react';
import {Maybe} from '@mindfulness/utils/maybe';

import {PlanWithInstallments} from '../../../types/types';
import {formatMoney} from '../../../utils';
import {Flex, Stack as Inner} from '../../layout';
import styles from './PlanCard.module.css';
import {H3, Small, Text} from '../../typography';

import {Glow, Outer, BestValueBadge} from './PlanCard.styles';

export const PlanCard: React.FC<
  PlanWithInstallments & {
    hasPayIn3: boolean;
    bestValue: boolean;
    billed: number;
    displayPrice: number;
    selectedPlan: Maybe<PlanWithInstallments>;
    trial: boolean;
    onChange: (plan: PlanWithInstallments) => void;
    index: number;
    trialAndDiscount: Maybe<boolean>;
  }
> = (plan) => {
  const {
    name,
    onChange,
    selectedPlan,
    bestValue,
    billed,
    trial,
    displayPrice,
    productCode,
    index,
    trialAndDiscount,
    payIn3,
    hasPayIn3,
  } = plan;

  const isEvent = React.useMemo(() => {
    return productCode === 'mcom-event';
  }, [productCode]);

  const isDiscountable = React.useMemo(
      () => trial && !trialAndDiscount,
      [trial, trialAndDiscount],
  );

  const price = React.useMemo(() => {
    // Discount price
    if ((!trial || trialAndDiscount) && plan?.discount?.finalPrice) {
      if (payIn3) {
        return formatMoney(Math.floor((plan.discount.finalPrice * 1.1) / 3));
      }
      return formatMoney(plan.discount.finalPrice);
    }
    if (payIn3) return formatMoney(Math.floor((displayPrice * 1.1) / 3));
    return formatMoney(displayPrice);
  }, [displayPrice, trial, payIn3]);

  const selected = React.useMemo(
      () => plan.id === selectedPlan?.id && payIn3 === selectedPlan?.payIn3,
      [selectedPlan?.id, selectedPlan?.payIn3, payIn3, plan.id],
  );

  const topText = React.useMemo(() => {
    if (payIn3) return `3-Monthly Installments`;
    if (hasPayIn3) return `One-time`;
    if (isEvent) return `Package ${index + 1}`;
    return name;
  }, [name, index, isEvent, payIn3, hasPayIn3]);

  const bottomText = React.useMemo(() => {
    if (isEvent) {
      return name;
    }
    if (billed === 0) {
      return 'billed once';
    }
    return `${formatMoney(billed)} / week`;
  }, [billed, isEvent, name]);

  // Money discounted is used to differentiate between a plan that has a discount
  // that is a trial as the price may not actually be changed.
  const moneyDiscounted = React.useMemo(() => {
    const {discount, renewalInterval} = plan;
    // Lifetime plans they are always discounted
    if (renewalInterval === 'NEVER' && discount) {
      return true;
    }
    // Its not discounted if there is a trial OR the discount object doesn't exist
    if (isDiscountable || !discount) {
      return false;
    }

    // Check whether the discount is monetary or not (could be a trial discount)
    const moneyOff = discount?.amountOff ?? discount.percentOff ?? false;
    // Only applicable if they are not accepting a trial
    return Boolean(moneyOff);
  }, [plan.discount, plan.renewalInterval, isDiscountable]);

  return (
    <Glow selected={selected}>
      <Outer
        onClick={(e) => {
          e.preventDefault();
          onChange(plan);
        }}
        selected={selected}
        type="button"
      >
        {bestValue && !isEvent && <BestValueBadge>Best Value</BestValueBadge>}
        <Inner
          className={styles.inner}
          direction="vertical"
          items="center"
          justify="space-between"
          space={14}
        >
          <Small fontWeight="bold" colour="grey7" fontSize={{xs: 'xs'}}>
            {topText}
          </Small>
          <Flex grow items="center" justify="center" column>
            {moneyDiscounted ? (
              <Text colour="grey5" decoration="line-through">
                {formatMoney(plan.priceUSD)}
              </Text>
            ) : null}
            <H3
              as="span"
              colour={
                (bestValue && !isEvent) || moneyDiscounted ?
                  'highlight' :
                  'grey9'
              }
              weight="bold"
            >
              {price}
            </H3>
          </Flex>
          <Small
            colour="grey9"
            fontWeight="bold"
            fontSize={{xs: 'xs'}}
            wrap="nowrap"
          >
            {bottomText}
          </Small>
        </Inner>
      </Outer>
    </Glow>
  );
};
