import {createElement, forwardRef, HTMLAttributes, PropsWithChildren} from 'react';
import {Theme} from 'shared/types/emotion';
import {ResponsiveValue} from 'shared/types/types';
import styleUtils from 'shared/styles/utils.module.css';
import styles from './Container.module.css';
import {getResponsiveClassNames} from 'shared/utils';

export const Container = forwardRef<HTMLDivElement, PropsWithChildren<HTMLAttributes<HTMLDivElement> & Props>>(({
  children, className, style,
  as = 'div',
  spacing = 0,
  maxWidth = 'xl',
  gutter = {xs: 16, md: 30},
  position,
  hide,
  zIndex,
  ...props
}, ref) => {
  return createElement(as, {
    className: [
      styles.container,
      styleUtils[`max-width-${maxWidth}`],
      ...(hide ? [styleUtils[`hidden-${hide[0]}-${hide[1]}`]] : []),
      ...(gutter !== undefined ? [
        ...getResponsiveClassNames(gutter, 'pl'),
        ...getResponsiveClassNames(gutter, 'pr'),
      ] : []),
      ...(spacing ? getResponsiveClassNames(spacing, 'mb') : []),
      ...(position ? [styleUtils[`position-${position}`]] : []),
      className,
    ].join(' '),
    style: {
      ...style,
      ...(zIndex ? {zIndex} : {}),
    },
    ...props,
    ref,
  }, children);
});


Container.displayName = 'Container';
type Props = {
  as?: keyof JSX.IntrinsicElements;
  spacing?: ResponsiveValue<keyof Theme['spacings']>;
  maxWidth?: keyof Theme['sizes']['container'] | 'prose';
  gutter?: ResponsiveValue<keyof Theme['spacings']>;
  position?: 'relative';
  hide?: [keyof Theme['breakpoints'], keyof Theme['breakpoints']];
  zIndex?: number;
}

