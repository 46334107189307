import * as React from 'react';
import {HeroRoundImage as HeroRoundImageProps} from '@mindfulness/cms';

import {H1, Text} from '../../typography';
import {Container, Row, Column} from '../../layout';
import {AppStoreLinksSmall} from '../../brand';
import {SectionIndex} from '../../../types/types';
import {BasicImage, CallToActionButton} from '../../global';

import styles from './HeroRoundImage.module.css';

export const HeroRoundImage: React.FC<SectionIndex<HeroRoundImageProps>> = ({
  eggImage,
  deviceImage,
  index,
  ...props
}) => (
  <section className={styles.wrapper}>
    <Container gutter={{xs: 0, md: 16}} position="relative">
      <Row className={styles.eggRow}>
        <Column span={{xs: 0, sm: 6, md: 6, lg: 12}} />
        <Column position='relative' span={{xs: 24, sm: 18, md: 18, lg: 12}}>
          {eggImage ? (
            <div className={styles.egg}>
              <BasicImage image={eggImage} />
            </div>
          ) : null}
        </Column>
      </Row>
      <Row items="center">
        <Column span={{xs: 0, lg: 8}}>
          <Content {...props} index={index} as="p" />
        </Column>
        <Column span={{xs: 18, sm: 14, md: 12, lg: 8}}></Column>
      </Row>
    </Container>
    <Container>
      <Row>
        <Column span={{xs: 24, lg: 0}}>
          <Content
            {...props}
            index={index}
            as={index === 0 ? undefined : 'h2'}
          />
        </Column>
      </Row>
    </Container>
  </section>
);

const Content: React.FC<
  SectionIndex<HeroRoundImageProps & { as?: React.ElementType<any> }>
> = ({paragraph, title, cta, _type, index, as}) => (
  <div className={styles.textWrapper}>
    <Row spacing={34}>
      <Column span={24}>
        {title ? (
          <H1 as={as} size="3xl" spacing={10}>
            {title}
          </H1>
        ) : null}
        {paragraph ? (
          <Text spacing={30} colour="grey7">
            {paragraph}
          </Text>
        ) : null}
        {cta?.text ? (
          <CallToActionButton id={`${_type}-${index}`} {...cta}>
            {cta?.text}
          </CallToActionButton>
        ) : null}
      </Column>
    </Row>
    <Row>
      <AppStoreLinksSmall />
    </Row>
  </div>
);
