import {createElement, CSSProperties, forwardRef, HTMLAttributes, PropsWithChildren} from 'react';
import {Theme} from 'shared/types/emotion';
import {ResponsiveValue} from 'shared/types/types';
import styleUtils from 'shared/styles/utils.module.css';
import {getResponsiveClassNames} from 'shared/utils';

export const Row = forwardRef<HTMLDivElement, PropsWithChildren<HTMLAttributes<HTMLDivElement> & Props>>(({
  children, className, style,
  as = 'div',
  spacing,
  topSpacing,
  gap,
  justify,
  items,
  reverse,
  column,
  wrap = 'wrap',
  hide,
  ...props
}, ref) => {
  return createElement(as, {
    className: [
      styleUtils.flex,
      ...(hide ? [styleUtils[`hidden-${hide[0]}-${hide[1]}`]] : []),
      styleUtils[`flex-${wrap}`],
      ...(column ? [styleUtils[`flex-col${reverse ? '-reverse' : ''}`]] : [styleUtils[`flex-row${reverse ? '-reverse' : ''}`]]),
      ...(justify ? [styleUtils[`justify-${justify}`]] : []),
      ...(items ? [styleUtils[`items-${items}`]] : []),
      ...getResponsiveClassNames(spacing, 'mb'),
      ...getResponsiveClassNames(topSpacing, 'mt'),
      ...getResponsiveClassNames(gap, 'gap-margin'),
      className,
    ].join(' '),
    style: {
      ...style,
    },
    ...props,
    ref,
  }, children);
});


Row.displayName = 'Row';
type Props = {
  as?: keyof JSX.IntrinsicElements;
  spacing?: ResponsiveValue<keyof Theme['spacings']>;
  topSpacing?: ResponsiveValue<keyof Theme['spacings']>;
  gap?: ResponsiveValue<keyof Theme['spacings']>;
  justify?: CSSProperties['justifyContent'];
  items?: CSSProperties['alignItems'];
  column?: boolean;
  reverse?: boolean;
  wrap?: CSSProperties['flexWrap'];
  hide?: [keyof Theme['breakpoints'], keyof Theme['breakpoints']];
}
