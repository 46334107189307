import styled from '@emotion/styled';

export const StyledContainer = styled.div(
    ({theme}) => `
  position: relative;
  overflow: hidden;
  padding: ${theme.spacings[40]} ${theme.spacings[30]};

  @media (min-width: ${theme.breakpoints.md.min}) {
    border-radius: ${theme.radius.lg};
    padding: ${theme.spacings[150]} ${theme.spacings[104]};
  }
`,
);

