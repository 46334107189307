import {createElement, CSSProperties, forwardRef, HTMLAttributes, PropsWithChildren} from 'react';
import {Theme} from 'shared/types/emotion';
import {ResponsiveValue} from 'shared/types/types';
import styleUtils from 'shared/styles/utils.module.css';
import styles from './Stack.module.css';
import {getResponsiveClassNames} from 'shared/utils';
import {isDefined} from '@mindfulness/utils/maybe';

export const Stack = forwardRef<HTMLDivElement, PropsWithChildren<HTMLAttributes<HTMLDivElement> & Props>>(({
  children, className, style,
  as = 'div',
  space = 0,
  direction = 'horizontal',
  items,
  justify,
  colour,
  fontWeight,
  wide,
  wrap = 'wrap',
  paddingY,
  spacing,
  tall,
  shrink,
  ...props
}, ref) => {
  return createElement(as, {
    className: [
      styles.stack,
      styles[`space-${space}`],
      styles[direction],
      styleUtils.flex,
      ...(isDefined(shrink) ? [styleUtils[`flex-shrink-0`]] : []),
      ...(wide ? [styleUtils['full-width']] : []),
      ...(tall ? [styleUtils['full-height']] : []),
      styleUtils[`flex-${wrap}`],
      ...(direction === 'vertical' ? [styleUtils['flex-col']] : [styleUtils['flex-row']]),
      ...(fontWeight ? [styleUtils[`font-weight-${fontWeight}`]] : []),
      ...(colour ? [styleUtils[`text-${colour}`]] : []),
      ...getResponsiveClassNames(items, 'items'),
      ...getResponsiveClassNames(justify, 'justify'),
      ...getResponsiveClassNames(spacing, 'mb'),
      ...(paddingY ? [styleUtils[`pt-${paddingY}`], styleUtils[`pb-${paddingY}`]] : []),
      className,
    ].join(' '),
    style: {
      ...style,
    },
    ...props,
    ref,
  }, children);
});


Stack.displayName = 'Stack';
type Props = {
  as?: keyof JSX.IntrinsicElements;
  spacing?: ResponsiveValue<keyof Theme['spacings']>;
  space?: keyof Theme['spacings'];
  direction?: 'horizontal' | 'vertical';
  items?: ResponsiveValue<CSSProperties['alignItems']>;
  justify?: ResponsiveValue<CSSProperties['justifyContent']>;
  colour?: keyof Theme['colors'];
  wide?: boolean;
  wrap?: CSSProperties['flexWrap'];
  paddingY?: keyof Theme['spacings'];
  fontWeight?: keyof Theme['fontWeights'];
  tall?: boolean;
  shrink?: 0;
}

