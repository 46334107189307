import {omitEmpty} from '@mindfulness/utils/object';
import {ReadonlyURLSearchParams, useParams, usePathname, useRouter, useSearchParams} from 'next/navigation';
import {unNull} from '../utils';
import {useMemo} from 'react';


const mergeParams = (existingParams: ReadonlyURLSearchParams, newParams: ParamsObject) => {
  const params = new URLSearchParams(existingParams.toString());
  Object.entries(newParams).forEach(([key, value]) => {
    params.set(key, value);
  });
  return params;
};

export const useNewNavigation = () => {
  const router = useRouter();
  const path = usePathname();
  const params = useParams();
  const searchParams = useSearchParams();

  const goTo = ({pathname = path, query = {}}: GoToParams) => {
    const params = new URLSearchParams(omitEmpty(query) as ParamsObject);
    router.push(`${pathname}${params ? `?${params.toString()}`: ''}`);
  };

  const goToReplace = ({pathname = path, query = {}}: GoToParams) => {
    const params = mergeParams(searchParams, omitEmpty(query) as ParamsObject).toString();
    router.replace(`${pathname}${params ? `?${params}`: ''}`);
  };

  const removeQueryParam = (paramToRemove: string) => {
    const current = new URLSearchParams(Array.from(searchParams.entries()));
    current.delete(paramToRemove);

    const search = current.toString();
    const query = search ? `?${search}` : '';
    router.replace(`${window.location.pathname}${query}`);
  };

  const removeQueryParams = (params: string[]) => {
    const current = new URLSearchParams(Array.from(searchParams.entries()));
    params.forEach((param) => {
      current.delete(param);
    });

    const search = current.toString();
    const query = search ? `?${search}` : '';
    router.replace(`${window.location.pathname}${query}`);
  };


  const getQueryParam = (name: string) => {
    return unNull(searchParams.get(name));
  };

  const getUtmParams = () => {
    return omitEmpty({
      utm_campaign: searchParams.get('utm_campaign'),
      utm_source: searchParams.get('utm_source'),
      utm_medium: searchParams.get('utm_medium'),
      utm_content: searchParams.get('utm_content'),
      utm_term: searchParams.get('utm_term'),
    }) as ParamsObject;
  };

  const goToHash = (hash: string) => {
    const params = searchParams.toString();
    router.push(`${path}#${hash}${params ? `?${params}` : ''}`);
  };

  const removeHash = () => {
    const params = searchParams.toString();
    router.replace(`${path}${params ? `?${params}` : ''}`);
  };

  const asPath = useMemo(() => {
    const paramString = searchParams.toString();
    return `${path}${params ? `?${paramString}` : ''}`;
  }, [searchParams, path, params]);


  const reload = () => {
    router.refresh();
  };

  return {
    pathname: path,
    asPath,
    goTo,
    goToReplace,
    getQueryParam,
    getUtmParams,
    goToHash,
    removeHash,
    removeQueryParam,
    removeQueryParams,
    reload,
  };
};

type ParamsObject = {[key: string]: string}
type MaybeParamsObject = {[key: string]: string | undefined}

type GoToParams = {
  pathname?: string,
  query?: MaybeParamsObject,
}
