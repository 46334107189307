import * as React from 'react';
import {Theme} from '@emotion/react';

import {
  CheckButton,
  CheckLabel,
} from './ButtonCheck.styles';

import {Box, Stack} from '../../layout';
import {Caption1, Small} from '../../typography';
import {APIImage} from '../../global/Image';

const ButtonCheck: React.FC<CheckButtonProps> = ({
  value,
  label,
  onChoice,
  checked,
  colour,
  image,
  hint,
  subtitle,
}) => {
  return (
    <CheckLabel checked={checked} background={colour}>
      <Stack space={10} items="center">
        {image && <APIImage url={image} alt={label} width={50} height={50} decoration="circle"/>}
        <Stack direction="vertical">
          <span>{label}</span>
          {subtitle && <Caption1>{subtitle}</Caption1>}
        </Stack>
      </Stack>
      <CheckButton
        type="checkbox"
        checked={checked}
        onChange={() => {
          onChoice?.(value);
        }}
      />
      {hint ? (
        <Box>
          <Small transform="uppercase" fontWeight="bold" fontSize="2xs" colour="grey5">{hint}</Small>
        </Box>
      ) : (
        <Box display={checked ? 'block' : 'none'}>
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.71225 1.83885C6.42526 0.694255 8.43921 0.0833282 10.4994 0.0833282C11.8674 0.0833282 13.2219 0.352763 14.4857 0.87625C15.7495 1.39974 16.8979 2.16702 17.8651 3.1343C18.8324 4.10158 19.5997 5.2499 20.1232 6.51371C20.6467 7.77752 20.9161 9.13206 20.9161 10.5C20.9161 12.5602 20.3052 14.5742 19.1606 16.2872C18.016 18.0002 16.3891 19.3353 14.4857 20.1237C12.5823 20.9122 10.4879 21.1184 8.46725 20.7165C6.44661 20.3146 4.59054 19.3225 3.13374 17.8657C1.67694 16.4089 0.684851 14.5528 0.282922 12.5322C-0.119008 10.5116 0.087277 8.41711 0.87569 6.51371C1.6641 4.61031 2.99923 2.98345 4.71225 1.83885ZM13.3323 7.15625L8.86356 11.6354L7.14481 9.91667C7.05143 9.80762 6.93651 9.71906 6.80728 9.65654C6.67804 9.59401 6.53728 9.55888 6.39382 9.55334C6.25037 9.5478 6.10731 9.57197 5.97364 9.62434C5.83997 9.67671 5.71856 9.75614 5.61705 9.85766C5.51553 9.95917 5.4361 10.0806 5.38373 10.2142C5.33136 10.3479 5.30719 10.491 5.31273 10.6344C5.31827 10.7779 5.3534 10.9187 5.41593 11.0479C5.47845 11.1771 5.56701 11.292 5.67606 11.3854L8.12398 13.8438C8.22131 13.9403 8.33674 14.0167 8.46366 14.0685C8.59057 14.1203 8.72647 14.1466 8.86356 14.1458C9.13683 14.1447 9.3987 14.0362 9.59273 13.8438L14.8011 8.63542C14.8987 8.53858 14.9762 8.42337 15.0291 8.29644C15.082 8.1695 15.1092 8.03335 15.1092 7.89583C15.1092 7.75832 15.082 7.62217 15.0291 7.49523C14.9762 7.3683 14.8987 7.25309 14.8011 7.15625C14.6059 6.96224 14.3419 6.85334 14.0667 6.85334C13.7915 6.85334 13.5275 6.96224 13.3323 7.15625Z"
              fill="#3D81F1"
            />
          </svg>
        </Box>
      )}
    </CheckLabel>
  );
};

export const CheckButtons: React.FC<
  Props & {
    onChoice?: (val: string) => void;
  }
> = ({options, maxAllowed, onChoice, colour}) => {
  return (
    <Stack role="group" direction="vertical" space={10}>
      {options.map((option) => (
        <ButtonCheck
          {...option}
          key={option.value}
          maxAllowed={maxAllowed}
          onChoice={onChoice}
          colour={colour}
        />
      ))}
    </Stack>
  );
};

export type CheckButtonItem = {
  label: string;
  /* Should be unique */
  value: string;
  maxAllowed?: number;
  onChoice?: (val: string) => void;
  checked: boolean;
  colour?: keyof Theme['colors'];
  hint?: string;
  image?: string;
  subtitle?: string;
};

export type CheckButtonProps = CheckButtonItem;

type Props = {
  // onChange: () => void;
  options: Array<CheckButtonItem>;
  maxAllowed?: number;
  colour?: keyof Theme['colors'];
};
