import styled from '@emotion/styled';
import {Theme} from '@emotion/react';

export const CheckButton = styled.input(
    () => `
  /* make it hidden */
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: transparent;
  /* Not removed via appearance */
  margin: 0;

`,
);

export const CheckLabel = styled.label<{
  checked: boolean;
  background?: keyof Theme['colors'];
}>(
    ({theme, checked, background = 'white'}) => `
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: space-between;
  border-radius: ${theme.radius.lg};
  border-width: 2px;
  border-style: solid;
  align-items: center;
  font-size: 1rem;
  font-weight: ${theme.fontWeights.bold};
  height: 67px;
  padding: 0 20px;

  transition-timing-function: ease;
  transition-property: border-color, color, background-color;
  transition-duration: 0.2s;
  will-change: border-color, color, background-color;

  ${
    checked ?
      `
    border-color: ${theme.colors.primary};
    color: ${theme.colors.primary};
    background-color: ${theme.colors.primaryPale};
  ` :
      `
    color: ${theme.colors.grey9};
    border-color: ${theme.colors.white};
    background-color: ${theme.colors[background]};

    &:hover {
      border-color: ${theme.colors.primary};
    color: ${theme.colors.primary};

    }

  `
}
`,
);
