import * as React from 'react';

import {billedWeekly, formatMoney} from '../../../utils';
import {Flex, Stack} from '../../layout';
import {H3, Small, Text} from '../../typography';

import {Glow, Outer} from './PurchaseCard.styles';
import styles from './PurchaseCard.module.css';
import {HydratedPurchase} from '../../../hooks/usePurchases';

export const PurchaseCard: React.FC<
  HydratedPurchase & {
    onChange: (plan: HydratedPurchase) => void;
    selected: boolean;
  }
> = ({onChange, selected, ...purchase}) => {
  const bottomText = React.useMemo(() => {
    if (!purchase.price) return;
    const billed = billedWeekly({
      renewalInterval: purchase.price.renewalInterval,
      price: purchase.price.priceUSD,
    });
    if (billed === 0) {
      return 'billed once';
    }
    return `${formatMoney(billed)} / week`;
  }, [purchase.price, purchase]);
  return (
    <Glow selected={selected}>
      <Outer
        onClick={(e) => {
          e.preventDefault();
          onChange(purchase);
        }}
        selected={selected}
        type="button"
      >
        <Stack
          direction="vertical"
          items="center"
          justify="space-between"
          space={14}
          className={styles.inner}
        >
          <Small fontWeight="bold" colour="grey7" fontSize={{xs: 'xs'}}>
            {purchase.purchase.title || purchase.subtitle}
          </Small>
          <Flex grow items="center" justify="center" column>
            {purchase.price?.comparePriceUSD || purchase.price?.discount ? (
              <Text colour="grey5" decoration="line-through">
                {formatMoney(
                    purchase.price?.comparePriceUSD || purchase.price?.priceUSD,
                )}
              </Text>
            ) : null}
            <H3
              as="span"
              colour={
                purchase.price?.comparePriceUSD || purchase.price?.discount ?
                  'highlight' :
                  'grey9'
              }
              weight="bold"
            >
              {formatMoney(
                  purchase?.price?.discount?.finalPrice ||
                  purchase.price?.priceUSD ||
                  0,
              )}
            </H3>
          </Flex>
          <Small
            colour="grey9"
            fontWeight="bold"
            fontSize={{xs: 'xs'}}
            wrap="nowrap"
          >
            {bottomText}
          </Small>
        </Stack>
      </Outer>
    </Glow>
  );
};
